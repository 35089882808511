import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export function formatMilliseconds(ms: number): string {
  // going negative because timespan is in the past
  const d = dayjs.duration(-ms)
  const days = d.days().toString()
  const hours = d.hours().toString().padStart(2, '0')
  const minutes = d.minutes().toString().padStart(2, '0')
  const seconds = d.seconds().toString().padStart(2, '0')

  return d.days()
    ? `${days} дн. ${hours}:${minutes}:${seconds}`
    : `${hours}:${minutes}:${seconds}`
}
