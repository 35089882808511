import { Button, Modal, Stack, Text, Title } from '@mantine/core'
import { useAppStore } from '@/store.ts'

export function RewardModal({ opened }: { opened: boolean }) {
  const acknowledgeReward = useAppStore((state) => state.acknowledgeReward)

  return (
    <Modal.Root
      centered
      opened={opened}
      onClose={close}
      transitionProps={{
        transition: 'fade',
        duration: 600,
        timingFunction: 'linear'
      }}
      closeOnClickOutside={false}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header style={{ backgroundColor: '#243b55' }}>
          <Modal.Title>
            <Title order={3}>Квест завершен!</Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#243b55' }}>
          <Stack gap={'md'}>
            <Text>HI</Text>
            <Button
              onClick={acknowledgeReward}
              autoContrast
              fullWidth
              color={'orange'}
            >
              Закрыть
            </Button>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
