import { Card, Group, Text } from '@mantine/core'
import {
  IconClockHour5,
  IconCoins,
  IconShieldChevron,
  IconSunLow
} from '@tabler/icons-react'
import { TCastleQuest } from '@/types.ts'
import { formatMilliseconds } from '@/utils.ts'

export function CastleQuestFooter({ quest }: { quest: TCastleQuest }) {
  return (
    <Card bg={'#243b55'} p={4} radius={'sm'} my={2}>
      <Group gap={'sm'} justify={'space-evenly'}>
        <Group align={'center'} gap={0}>
          <IconShieldChevron
            strokeWidth={2}
            style={{ height: 14, color: '#d68516' }}
          />
          <Text c={'#fff'}>{quest.minLevel}</Text>
        </Group>{' '}
        <Group align={'center'} gap={0}>
          <IconClockHour5
            strokeWidth={2}
            style={{ height: 14, color: '#ccc' }}
          />
          <Text c={'#fff'}>
            {formatMilliseconds(quest.baseCompletionTimeSec * -1000)}
          </Text>
        </Group>
        <Group align={'center'} gap={0}>
          <IconCoins strokeWidth={2} style={{ height: 14, color: '#daa520' }} />
          <Text c={'#fff'}>{quest.goldReward}g</Text>
        </Group>
        <Group align={'center'} gap={0}>
          <IconSunLow
            strokeWidth={2}
            style={{ height: 14, color: 'greenyellow' }}
          />
          <Text c={'#fff'}>{quest.baseExp}xp</Text>
        </Group>
      </Group>
    </Card>
  )
}
