import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import '@mantine/core/styles.css'
import { MantineProvider } from '@mantine/core'
import { SDKProvider } from '@telegram-apps/sdk-react'
import { IntlProvider } from 'react-intl'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Overview } from '@/components/Overview.tsx'
import './mockEnv.ts'
import './index.css'
import { Root } from '@/components/Root.tsx'
import { Castle } from '@/components/Castle.tsx'
import { ActivityList } from '@/components/ActivityList.tsx'
import { CastleAvailableQuests } from '@/components/CastleAvailableQuests.tsx'
import { CastleCompletedQuests } from '@/components/CastleCompletedQuests.tsx'

import ru from '../lang/ru.json'

Sentry.init({
  dsn: 'https://33249f2efe5d0c83df425a9012663918@o4507623931576320.ingest.de.sentry.io/4507623933345872',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', /^https:\/\/api\.prcvl\.online\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: 'overview',
        element: <Overview />,
        children: [
          {
            index: true,
            element: <ActivityList />
          }
        ]
      },
      {
        path: 'castle',
        element: <Castle />,
        children: [
          {
            path: 'available',
            element: <CastleAvailableQuests />
          },
          {
            path: 'completed',
            element: <CastleCompletedQuests />
          },
          {
            index: true,
            element: <CastleAvailableQuests />
          }
        ]
      }
    ]
  }
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <IntlProvider messages={ru} locale={'ru'} defaultLocale={'ru'}>
    <MantineProvider
      theme={{
        fontFamily: 'Jost',
        headings: { fontFamily: 'Jost' }
      }}
    >
      <SDKProvider acceptCustomStyles debug>
        <RouterProvider router={router} />
      </SDKProvider>
    </MantineProvider>
  </IntlProvider>
)
