import { Stack, Title, Text, Card, Button } from '@mantine/core'

import { selectCharacter, useAppStore } from '@/store.ts'
import { CastleQuestFooter } from '@/ui/CastleQuestFooter.tsx'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useToggle } from '@mantine/hooks'
import { formatMilliseconds } from '@/utils.ts'

const TIMER_UPDATE_INTERVAL_MS = 1000

export function InProgressInfo() {
  const character = useAppStore(selectCharacter)
  const completeQuest = useAppStore((state) => state.completeQuest)
  const cancelQuest = useAppStore((state) => state.cancelQuest)

  const [timeLeft, setTimeLeft] = useState<string>('')
  const [questCompleted, setQuestCompleted] = useState<boolean>(false)
  const [focused, toggle] = useToggle()

  useEffect(() => {
    const interval = setInterval(updateTime, TIMER_UPDATE_INTERVAL_MS)
    updateTime()
    return () => clearInterval(interval)
  }, [])

  function updateTime() {
    console.log('time ping')
    console.log(character)
    if (!character?.castleQuests?.inProgress?.quest) return
    console.log('got active quest')

    const startedAt = dayjs(character.castleQuests.inProgress.startedAt)
    const questLength =
      character.castleQuests.inProgress.quest.baseCompletionTimeSec
    const endsAt = startedAt.add(questLength, 'seconds')

    if (dayjs().isAfter(endsAt)) {
      setQuestCompleted(true)
      setTimeLeft('')
    } else {
      setTimeLeft(formatMilliseconds(dayjs().diff(endsAt)))
    }
  }

  if (!character?.castleQuests?.inProgress?.quest) return null

  const quest = character.castleQuests.inProgress.quest

  async function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    await cancelQuest(quest._id)
  }

  async function handleCompleteClick(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.stopPropagation()
    await completeQuest(quest._id)
  }

  function renderQuestControls() {
    if (!focused && !questCompleted) return null
    if (questCompleted) {
      return (
        <Button
          onClick={handleCompleteClick}
          mt={'xs'}
          variant={'filled'}
          color="green"
        >
          Завершить квест
        </Button>
      )
    }

    return (
      <Button
        onClick={handleCancelClick}
        mt={'xs'}
        variant={'filled'}
        color="red"
      >
        Отменить квест
      </Button>
    )
  }

  return (
    <Stack gap={0} onClick={() => toggle()}>
      <Title order={2}>Активный квест</Title>
      <Card bg={'#e3dab1'} p={'sm'}>
        <Title order={4}>{quest.name}</Title>
        <Text>{quest.description}</Text>
        {questCompleted ? (
          <Text>Квест завершен</Text>
        ) : (
          <Text>До завершения: {timeLeft}</Text>
        )}
        <CastleQuestFooter quest={quest} />
        {renderQuestControls()}
      </Card>
    </Stack>
  )
}
