import { Navigate, Outlet } from 'react-router'
import { Box } from '@mantine/core'

import { retrieveLaunchParams, initMiniApp } from '@telegram-apps/sdk'
import { useAppStore } from '@/store.ts'
import { useEffect } from 'react'
import { Flip, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RewardModal } from '@/ui/RewardModal.tsx'

interface CustomWindow {
  TelegramWebviewProxy: {
    postEvent(p: string, v: string): void
  }
}

declare const window: CustomWindow

export function Root() {
  const [miniApp] = initMiniApp()
  const { initDataRaw } = retrieveLaunchParams()

  const user = useAppStore((s) => s.user)
  const getUser = useAppStore((s) => s.getUser)
  const reward = useAppStore((s) => s.reward)

  useEffect(() => {
    if (initDataRaw) {
      getUser(initDataRaw)
      miniApp.setHeaderColor('#243b55')
      miniApp.setBgColor('#243b55')
    }
  }, [initDataRaw])

  useEffect(() => {
    if (window.TelegramWebviewProxy?.postEvent) {
      window.TelegramWebviewProxy.postEvent(
        'web_app_setup_swipe_behavior',
        JSON.stringify({ allow_vertical_swipe: false })
      )
    }
  }, [window.TelegramWebviewProxy?.postEvent])

  return (
    <Box pt={'md'}>
      {user && <Navigate to={'/overview'} />}
      <Outlet />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick
        pauseOnHover={false}
        draggable={false}
        theme="dark"
        transition={Flip}
      />
      <RewardModal opened={Boolean(reward)} />
    </Box>
  )
}
