import { create } from 'zustand'
import { TCastleQuest, TCharacter, TUser } from '@/types.ts'
import { api } from '@/api.ts'

interface AppState {
  user: TUser | null
  character: TCharacter | null
  getUser: (initDataRaw: string) => Promise<void>
  startNewQuest: (questId: string) => Promise<TCharacter | null>
  completeQuest: (questId: string) => Promise<TCharacter | null>
  cancelQuest: (questId: string) => Promise<TCharacter | null>
  castleQuests: Array<TCastleQuest>
  getCastleQuests: () => Promise<void>
  setCharacter: (character: TCharacter) => void
  reward?: { gold?: number; exp?: number; keyCount?: number } | null
  acknowledgeReward: () => void
}

const serverUrl = import.meta.env.VITE_SERVER_URL || ''

const useAppStore = create<AppState>((set) => ({
  user: null,
  character: null,
  castleQuests: [],
  reward: null,

  getUser: async (initDataRaw: string) => {
    try {
      const response = await fetch(serverUrl + `/api/init?${initDataRaw}`)
      if (response.ok) {
        const data: { user: TUser; token: string } = await response.json()
        set({ user: data.user, character: data.user.character })
        localStorage.setItem('auth_token', data.token)
      }
    } catch (e) {
      console.error(e)
    }
  },

  setCharacter: (character: TCharacter) => set({ character }),

  startNewQuest: async (questId: string) =>
    await updateCharacterState(`/api/castle-quests/${questId}/start`),

  completeQuest: async (questId: string) =>
    await updateCharacterState(`/api/castle-quests/${questId}/complete`),

  cancelQuest: async (questId: string) =>
    await updateCharacterState(`/api/castle-quests/${questId}/cancel`),

  getCastleQuests: async () => {
    try {
      const response = await api.get('/api/castle-quests')
      if (response?.data) {
        set({ castleQuests: response.data })
      }
    } catch (e) {
      console.error(e)
    }
  },
  acknowledgeReward: () => set({ reward: null })
}))

async function updateCharacterState(url: string): Promise<TCharacter | null> {
  try {
    const response = await api.post(url)
    useAppStore.setState({
      character: response.data.character,
      reward: response.data.reward
    })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

function handleError(error: unknown): null {
  console.error('An error occurred:', error)
  return null
}

const selectCharacter = (state: AppState) => state.character
const selectCastleQuests = (state: AppState) => state.castleQuests

export { useAppStore, selectCharacter, selectCastleQuests }
