import { Group, Image, Stack, Text } from '@mantine/core'
import { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppStore, selectCharacter } from '@/store.ts'
import { useHapticFeedback } from '@telegram-apps/sdk-react'
import { IconShieldChevron } from '@tabler/icons-react'

interface P {
  image: string
  name: string
  path: string
  description: string
  levelRequirement?: number
}

export function Banner({
  image,
  name,
  levelRequirement = 1,
  path,
  description
}: P) {
  const character = useAppStore(selectCharacter)
  const navigate = useNavigate()
  const hapticFeedback = useHapticFeedback()

  if (!character) return null

  const disabled = character.level < levelRequirement

  const bannerStyle = {
    objectFit: 'cover',
    objectPosition: 'center',
    maxHeight: '60px',
    border: '3px solid #daa520',
    borderRadius: '6px',
    filter: disabled ? 'grayscale(95%)' : 'none'
  } as CSSProperties

  const handleClick = () => {
    if (disabled) return null
    hapticFeedback.impactOccurred('soft')
    navigate(path)
  }

  const renderLevelRequirement = () => {
    return (
      <Group gap={1} p={0} align={'center'}>
        <IconShieldChevron
          strokeWidth={2}
          style={{ height: 18, color: '#fff' }}
        />
        <Text size={'18px'}>{levelRequirement}</Text>
      </Group>
    )
  }

  return (
    <Group onClick={handleClick} align={'center'} gap={'xs'} w={'100%'} h={60}>
      <Image w={'100%'} src={image} style={bannerStyle} />
      <Group justify={'space-between'} align={'center'} flex={1}>
        <Stack gap={0}>
          <Text
            style={{
              fontWeight: '600',
              textTransform: 'uppercase',
              letterSpacing: 1
            }}
          >
            {name}
          </Text>
          <Text size={'xs'}>{description}</Text>
        </Stack>
        {renderLevelRequirement()}
      </Group>
    </Group>
  )
}
