import { Group, Stack, Text, Title } from '@mantine/core'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { Outlet } from 'react-router'
import { IconArrowLeft } from '@tabler/icons-react'
import { useAppStore } from '@/store.ts'
import { FormattedMessage } from 'react-intl'

export function Castle() {
  const getCastleQuests = useAppStore((state) => state.getCastleQuests)

  useEffect(() => {
    async function run() {
      await getCastleQuests()
    }

    run()
  }, [])

  return (
    <Stack gap={'xs'} px={'md'}>
      <Group gap={'md'} align={'center'} mt={'sm'}>
        <Link to={'/overview'}>
          <IconArrowLeft
            strokeWidth={2}
            style={{ height: 32, color: '#fff' }}
          />
        </Link>
        <Stack gap={0}>
          <Title order={3}>
            <FormattedMessage id={'castle'} />
          </Title>
          <Text size={'xs'}>
            <FormattedMessage id={'castle_description'} />
          </Text>
        </Stack>
      </Group>
      {/*<Button.Group mt={'xs'}>*/}
      {/*  <Button*/}
      {/*    component={Link}*/}
      {/*    to={'available'}*/}
      {/*    fullWidth*/}
      {/*    variant="light"*/}
      {/*    color="#daa520"*/}
      {/*  >*/}
      {/*    Available*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    component={Link}*/}
      {/*    to={'completed'}*/}
      {/*    fullWidth*/}
      {/*    variant="light"*/}
      {/*    color="#daa520"*/}
      {/*  >*/}
      {/*    Completed*/}
      {/*  </Button>*/}
      {/*</Button.Group>*/}
      <Outlet />
    </Stack>
  )
}
