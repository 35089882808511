import { Box, Stack, Text, Title } from '@mantine/core'
import { Outlet } from 'react-router'
import { CharacterTopBar } from '@/ui/CharacterTopBar.tsx'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { CarouseCard } from '@/ui/CarouseCard.tsx'
import { InProgressInfo } from '@/ui/InProgressInfo.tsx'

const displayCarousel = false

export function Overview() {
  return (
    <Stack gap={0}>
      <CharacterTopBar />
      {displayCarousel && (
        <Box mt={'md'} px={'md'}>
          <Carousel
            infiniteLoop
            showThumbs={false}
            showArrows={false}
            showStatus={false}
          >
            <CarouseCard bg={'red'}>
              <Title order={3}>Start new quest!</Title>
              <Text>New adventures await you in the Castle</Text>
            </CarouseCard>
            <CarouseCard bg={'red'}>
              <Title order={3}>Start new quest!</Title>
              <Text>New adventures await you in the Castle</Text>
            </CarouseCard>
            <CarouseCard bg={'red'}>
              <Title order={3}>Start new quest!</Title>
              <Text>New adventures await you in the Castle</Text>
            </CarouseCard>
          </Carousel>
        </Box>
      )}
      <Box p="md">
        <InProgressInfo />
      </Box>
      <Outlet />
    </Stack>
  )
}
