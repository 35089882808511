import { useAppStore, selectCharacter } from '@/store.ts'
import { Button, Stack, Text, Title } from '@mantine/core'
import { IconSwords } from '@tabler/icons-react'
import { Link } from 'react-router-dom'
import { CastleQuestFooter } from '@/ui/CastleQuestFooter.tsx'
import { TCastleQuest } from '@/types.ts'

export function CastleCompletedQuests() {
  const character = useAppStore(selectCharacter)

  if (!character) return null

  if (!character.castleQuests.completed?.length) {
    return (
      <Stack justify="center" align={'center'} mt={80}>
        <IconSwords
          strokeWidth={1}
          style={{ width: 64, height: 64, color: '#daa520' }}
        />
        <Title order={4}>No completed quests yet</Title>
        <Button
          color="#daa520"
          autoContrast
          radius={'md'}
          size={'md'}
          component={Link}
          to={'../available'}
        >
          Start a new quest
        </Button>
      </Stack>
    )
  }

  return (
    <Stack>
      {character.castleQuests.completed.map((quest: TCastleQuest) => (
        <Stack
          p={'xs'}
          key={quest._id}
          gap={2}
          style={{
            border: '1px solid rgba(255,255,255, 0.2)',
            borderRadius: '6px'
          }}
        >
          <Title c={'green'} order={5} lh={1}>
            {quest.name}
          </Title>
          <Text size={'sm'}>{quest.description}</Text>
          <CastleQuestFooter quest={quest} />
        </Stack>
      ))}
    </Stack>
  )
}
