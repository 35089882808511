const API_BASE_URL = import.meta.env.VITE_SERVER_URL || ''
const TOKEN_KEY = 'auth_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

async function fetchWithAuth(endpoint: string, options = {}) {
  const token = localStorage.getItem(TOKEN_KEY)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : ''
  }

  const config = { ...options, headers }

  try {
    let response = await fetch(`${API_BASE_URL}${endpoint}`, config)

    if (response.status === 401) {
      const newToken = await refreshToken()
      if (newToken) {
        config.headers['Authorization'] = `Bearer ${newToken}`
        response = await fetch(`${API_BASE_URL}${endpoint}`, config)
      }
    }

    if (!response.ok) {
      return { error: response.statusText }
    }

    return await response.json()
  } catch (error) {
    return { error }
  }
}

async function refreshToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)
  if (!refreshToken) {
    logout()
    return null
  }

  try {
    const response = await fetch(`${API_BASE_URL}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken })
    })

    if (!response.ok) {
      throw new Error('Failed to refresh token')
    }

    const data = await response.json()
    localStorage.setItem(TOKEN_KEY, data.token)
    return data.token
  } catch (error) {
    console.error('Token refresh failed:', error)
    logout()
    return null
  }
}

function logout() {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
  window.location.href = '/'
}

const api = {
  get: (endpoint: string, options = {}) =>
    fetchWithAuth(endpoint, { ...options, method: 'GET' }),
  post: (endpoint: string, body: unknown = {}, options = {}) =>
    fetchWithAuth(endpoint, {
      ...options,
      method: 'POST',
      body: JSON.stringify(body)
    }),
  put: (endpoint: string, body: unknown, options = {}) =>
    fetchWithAuth(endpoint, {
      ...options,
      method: 'PUT',
      body: JSON.stringify(body)
    }),
  delete: (endpoint: string, options = {}) =>
    fetchWithAuth(endpoint, {
      ...options,
      method: 'DELETE'
    })
}

export { api }
