import { Stack, Title } from '@mantine/core'
import { Banner } from '@/ui/Banner.tsx'
import Castle from '../../assets/art/castle.png'
import Tavern from '../../assets/art/tavern.png'
import Cave from '../../assets/art/cave.png'
import { useIntl } from 'react-intl'

export function ActivityList() {
  const intl = useIntl()

  return (
    <Stack gap={'sm'} w={'100%'} px={'md'}>
      <Title order={2}>{intl.formatMessage({ id: 'activities' })}</Title>
      <Banner
        image={Castle}
        path={'/castle'}
        name={intl.formatMessage({ id: 'castle' })}
        description={intl.formatMessage({ id: 'castle_description' })}
      />
      <Banner
        image={Tavern}
        name={intl.formatMessage({ id: 'tavern' })}
        description={intl.formatMessage({ id: 'tavern_description' })}
        levelRequirement={3}
        path={'/tavern'}
      />
      <Banner
        image={Cave}
        name={intl.formatMessage({ id: 'cave' })}
        description={intl.formatMessage({ id: 'cave_description' })}
        levelRequirement={5}
        path={'/cave'}
      />
    </Stack>
  )
}
