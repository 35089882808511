import { Group, Image, Text } from '@mantine/core'
import { useAppStore } from '@/store.ts'
import {
  IconCoins,
  IconHexagons,
  IconShieldChevron,
  IconUsers
} from '@tabler/icons-react'
import Knight from '../../assets/art/knight.png'

export function CharacterTopBar() {
  const user = useAppStore((s) => s.user)
  const character = useAppStore((s) => s.character)

  if (!user || !character) return null

  return (
    <Group align={'center'} justify={'center'} gap={'sm'} px={'md'}>
      <Image
        w={40}
        h={40}
        src={Knight}
        style={{ borderRadius: '50%', border: '2px solid #daa520' }}
      />
      <Group align={'center'} justify={'space-evenly'} flex={1}>
        <Group gap={2}>
          <IconShieldChevron
            strokeWidth={2}
            style={{ height: 16, color: '#d68516' }}
          />
          <Text size={'18px'}>{character.level}</Text>
        </Group>
        <Group gap={4}>
          <IconCoins strokeWidth={2} style={{ height: 16, color: '#daa520' }} />
          <Text size={'18px'}>{character.gold}</Text>
        </Group>
        <Group gap={4}>
          <IconHexagons
            strokeWidth={2}
            style={{ height: 16, color: '#20c60d' }}
          />
          <Text size={'18px'}>{character.currentExp}</Text>
        </Group>
        <Group gap={4}>
          <IconUsers strokeWidth={2} style={{ height: 16, color: '#dd126f' }} />
          <Text size={'18px'}>0</Text>
        </Group>
      </Group>
    </Group>
  )
}
