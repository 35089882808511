import { Button, Stack, Text, Title } from '@mantine/core'
import { useAppStore, selectCharacter, selectCastleQuests } from '@/store.ts'
import { useState } from 'react'
import { CastleQuestFooter } from '@/ui/CastleQuestFooter.tsx'
import { InProgressInfo } from '@/ui/InProgressInfo.tsx'

export function CastleAvailableQuests() {
  const character = useAppStore(selectCharacter)
  const castleQuests = useAppStore(selectCastleQuests)
  const startNewQuest = useAppStore((state) => state.startNewQuest)
  const setCharacter = useAppStore((state) => state.setCharacter)

  const [expandedQuestId, setExpandedQuestId] = useState<string>('')

  if (!character) return null

  async function handleQuestStartClick() {
    const result = await startNewQuest(expandedQuestId)
    if (result) {
      setCharacter(result)
    }
  }

  const availableQuests = castleQuests.filter((q) => {
    return (
      q.minLevel <= character.level &&
      q._id !== character.castleQuests?.inProgress?.quest?._id &&
      character.castleQuests?.completed.findIndex((e) => e._id === q._id) === -1
    )
  })

  return (
    <Stack h={'100%'} gap={8} pb={'xl'} pt={'sm'}>
      {character.castleQuests?.inProgress?.quest && <InProgressInfo />}
      <Title order={2} mt={'xs'}>
        Доступные квесты
      </Title>
      {availableQuests.map((quest) => (
        <Stack
          p={'xs'}
          key={quest._id}
          gap={2}
          style={{
            border: '1px solid rgba(255,255,255, 0.2)',
            borderRadius: '6px'
          }}
          onClick={() => setExpandedQuestId(quest._id)}
        >
          <Title c={'green'} order={5} lh={1}>
            {quest.name}
          </Title>
          {expandedQuestId === quest._id && (
            <Text size={'sm'}>{quest.description}</Text>
          )}
          <CastleQuestFooter quest={quest} />
          {expandedQuestId === quest._id && (
            <Button onClick={handleQuestStartClick} mt={'xs'}>
              Start quest
            </Button>
          )}
        </Stack>
      ))}
    </Stack>
  )
}
